<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <div class="container mt-3">
        <div class="row">
            <div class="col-3 table-menu-left">
                <div class="text-center mt-2 mb-2">
                    <a>
                        <router-link to="/visi_misi">
                            <img src="../../assets/images/visi_misi.png" class="icon-menu">
                        </router-link>
                    </a>
                    <p class="text-white text-menu">VISI & MISI</p>
                </div>
            </div>

            <div class="col-3 table-menu-right">
                <div class="text-center mt-2 mb-2">
                    <a>
                        <router-link to="/budaya">
                            <img src="../../assets/images/budaya.png" class="icon-menu">
                        </router-link>
                    </a>
                    <p class="text-white text-menu">BUDAYA PERUSAHAAN</p>
                </div>
            </div>

            <div class="col-3 table-menu-left">
                <div class="text-center mt-2 mb-2">
                    <a>
                        <router-link to="/manajemen">
                            <img src="../../assets/images/manajemen.png" class="icon-menu">
                        </router-link>
                    </a>
                    <p class="text-white text-menu">JAJARAN MANAJEMEN</p>
                </div>
            </div>

            <div class="col-3 table-menu-right">
                <div class="text-center mt-2 mb-2">
                    <a>
                        <router-link to="/tata_kelola">
                            <img src="../../assets/images/tata_kelola.png" class="icon-menu">
                        </router-link>
                    </a>
                    <p class="text-white text-menu">TATA KELOLA</p>
                </div>
            </div>
        </div>

        <div class="border-bottom-red"></div>
    </div>

    <div class="container-fluid mt-3 border-bottom-red">
        <div class="row">
            <div class="col-2">
                <div class="content-block">
                    <img class="icon-red img-left" src="../../assets/images/sejarah.png" alt="icon">
                </div>
            </div>

            <div class="col-10">
                <div class="content-block">
                    <h3> <span class="text-primary">SEJARAH SINGKAT</span> <span class="text-danger">BAHTERA ADHIGUNA</span></h3>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item text-justify">
                            Tahun 1961, Pemerintah Republik Indonesia telah menasionalisasikan perusahaan milik Belanda NV Netherlandsch-Indische Steenkolen Handel-Maatschappij (NV NISHM) dan diubah jadi PN Menunda Kapal Tundabara dengan bisnis utama adalah jasa angkutan batubara dan jasa kapal pandu. Tahun 1966, PN Menunda Kapal Tundabara diubah jadi PN Bahtera Adhiguna.
                        </li>
                        <li class="list-group-item text-justify">
                            Tahun 1971, berdasarkan peraturan pemerintah PN Bahtera Adhiguna, diubah jadi PT Bahtera Adhiguna (Persero) dan aktivitas kegiatannya adalah jasa angkutan barang curah dan kargo umum, angkutan kayu, jasa keagenan kapal, EMKL, bongkar muat dari/ke kapal. 
                        </li>
                        <li class="list-group-item text-justify">
                            Tahun 1985, PT Bahtera Adhiguna telah mendirikan anak perusahaan yaitu, PT PBM Adhiguna Putera yang khusus menangani bongkar muat dari/ke kapal.
                        </li>
                        <li class="list-group-item text-justify">
                            Pada Bulan Maret 2011, Pemerintah Republik Indonesia telah menetapkan penambahan penyertaan modal Negara ke dalam modal saham Perusahaan Perseroan (Persero) PT Perusahaan Listrik Negara berasal dari pengalihan seluruh saham milik Negara Republik Indonesia kepada Perusahaan Perseroan (Persero) PT Pelayaran Bahtera Adhiguna.
                        </li>
                        <li class="list-group-item text-justify">
                            Pada Bulan Mei 2011, Kementerian BUMN menerbitkan surat keputusan tetang Pengalihan Modal Saham Milik Negara Republik Indonesia dari Perusahaan Perseroan(Persero) PT Pelayaran Bahtera Adhiguna kepada perusahaan Perseroan (Persero) PT Perusahaan Listrik Negara.
                        </li>
                        <li class="list-group-item text-justify">
                            Pada Bulan Agustus 2011, telah diadakan penandatanganan Akta pemindahtanganan hak atas saham milik Negara Republik Indonesia pada Perusahaan (Persero) PT Pelayaran Bahtera Adhiguna kepada Perusahaan Perseroan (Persero) PT Perusahaan Listrik Negara.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageTentangKami',
    components: {
        Banner
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
        }
    },
    mounted () {
        this.loadBanner()
    },
    methods: {
        loadBanner () {
            axios.get('tk_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_indo
                })
        }
    }
}
</script>

<style scoped>
    .table-menu-left {
        background:#0038BA
    }

    .table-menu-right {
        background:#004BFA
    }

    .icon-menu {
        width: 50px;
        height: 50px;
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 720px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    @media screen and (max-width: 720px) {
        .text-menu {
            font-size: 10px;
        }
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

</style>